import AbstractService from "./abstract.service"
import slugify from "slugify"
import sandiqShopProducts from '@/data/sandiqshop-products.json'
import sandiqShopProductsUz from '@/data/sandiqshop-products-uz.json'
import i18n from '../i18n/i18n';

class ProductService extends AbstractService {
	constructor() {
		super()
	}

	static BASE_URL = 'https://api.sandiqshop.uz/'
	static VERSION_API = 'v1'

	products = []

	static getAll() {
		const { results: products } = i18n.locale === 'ru' ? sandiqShopProducts : sandiqShopProductsUz

		return products.map(product => {
			return {
				...product,
				slug: slugify(product.public_name, { lower: true }),
				name: product.public_name,
				formattedImages: product.images.map(image => image.file),
				price: product.public_product_prices.find(({ installment }) => installment === 4).price
			}
		})
	}

	static getOne(productId) {
		const products = i18n.locale === 'ru' ? sandiqShopProducts : sandiqShopProductsUz
		const product = products.results.find(product => product.id === Number(productId))

		return {
			...product,
			name: product.public_name,
			formattedImages: product.images.map(image => image.file),
			price: product.public_product_prices.find(({ installment }) => installment === 4).price
		}
	}
}

export default ProductService